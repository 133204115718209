export type Locale = "en" | "de";

export const localePath = {
  de: {
    categories: "/kategorien",
    packages: "/pakete",
    activities: "/aktivitaten",
    checkout: {
      index: "/checkout",
      personalDetails: "/checkout/personal-details",
      paymentSelection: "/checkout/payment-selection",
      overview: "/checkout/overview",
      thankYou: "/checkout/thank-you"
    },
  },
  en: {
    categories: "/categories",
    packages: "/packages",
    activities: "/activities",
    checkout: {
      index: "/checkout",
      personalDetails: "/checkout/personal-details",
      paymentSelection: "/checkout/payment-selection",
      overview: "/checkout/overview",
      thankYou: "/checkout/thank-you"
    },
  },
};
