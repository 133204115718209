/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompleteOrderMutation
// ====================================================

export interface CreateCompleteOrderMutation_createCompleteOrder {
  __typename: "Orders";
  id: string;
  publicId: string | null;
}

export interface CreateCompleteOrderMutation {
  createCompleteOrder: CreateCompleteOrderMutation_createCompleteOrder;
}

export interface CreateCompleteOrderMutationVariables {
  input: CreateCompleteOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderMutation
// ====================================================

export interface UpdateOrderMutation_updateOrder_order {
  __typename: "Orders";
  id: string;
}

export interface UpdateOrderMutation_updateOrder {
  __typename: "updateOrderPayload";
  order: UpdateOrderMutation_updateOrder_order | null;
}

export interface UpdateOrderMutation {
  updateOrder: UpdateOrderMutation_updateOrder | null;
}

export interface UpdateOrderMutationVariables {
  input: updateOrderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderQuery
// ====================================================

export interface HeaderQuery_header_menuItems {
  __typename: "ComponentHeaderMenuItem";
  id: string;
  slug: string;
  title: string;
}

export interface HeaderQuery_header_languageItems_image {
  __typename: "UploadFile";
  id: string;
  url: string;
}

export interface HeaderQuery_header_languageItems {
  __typename: "ComponentHeaderLanguageItem";
  id: string;
  title: string | null;
  value: string | null;
  image: HeaderQuery_header_languageItems_image | null;
}

export interface HeaderQuery_header {
  __typename: "Header";
  menuItems: (HeaderQuery_header_menuItems | null)[] | null;
  languageItems: (HeaderQuery_header_languageItems | null)[] | null;
}

export interface HeaderQuery {
  header: HeaderQuery_header | null;
}

export interface HeaderQueryVariables {
  locale?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomePagequery
// ====================================================

export interface HomePagequery_homepage_seo {
  __typename: "ComponentGlobalSeo";
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface HomePagequery_homepage_categories_image {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface HomePagequery_homepage_categories {
  __typename: "Category";
  id: string;
  slug: string;
  title: string;
  image: HomePagequery_homepage_categories_image | null;
}

export interface HomePagequery_homepage_activities {
  __typename: "Activity";
  id: string;
  slug: string;
  title: string;
  order: number | null;
}

export interface HomePagequery_homepage_presentationItems {
  __typename: "ComponentHomepagePresentationItems";
  title: string;
  order: number | null;
}

export interface HomePagequery_homepage {
  __typename: "Homepage";
  seo: HomePagequery_homepage_seo | null;
  categories: (HomePagequery_homepage_categories | null)[] | null;
  activities: (HomePagequery_homepage_activities | null)[] | null;
  presentationTitle: string | null;
  presentationItems: (HomePagequery_homepage_presentationItems | null)[] | null;
  ourGoalTitle: string | null;
  ourGoalDescription: string | null;
}

export interface HomePagequery {
  homepage: HomePagequery_homepage | null;
}

export interface HomePagequeryVariables {
  locale: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ThankYouPageQuery
// ====================================================

export interface ThankYouPageQuery_thankYouPage {
  __typename: "ThankYouPage";
  text: string | null;
}

export interface ThankYouPageQuery {
  thankYouPage: ThankYouPageQuery_thankYouPage | null;
}

export interface ThankYouPageQueryVariables {
  locale: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FooterQuery
// ====================================================

export interface FooterQuery_footer_links {
  __typename: "ComponentFooterFooterLinks";
  title: string | null;
  url: string;
}

export interface FooterQuery_footer_socialNetworks_image {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface FooterQuery_footer_socialNetworks {
  __typename: "ComponentFooterSocialNetworks";
  url: string;
  image: FooterQuery_footer_socialNetworks_image | null;
}

export interface FooterQuery_footer_contacts_image {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface FooterQuery_footer_contacts {
  __typename: "ComponentFooterContacts";
  image: FooterQuery_footer_contacts_image | null;
  link: string;
  title: string | null;
  type: ENUM_COMPONENTFOOTERCONTACTS_TYPE;
}

export interface FooterQuery_footer {
  __typename: "Footer";
  links: (FooterQuery_footer_links | null)[] | null;
  socialNetworks: (FooterQuery_footer_socialNetworks | null)[] | null;
  contacts: (FooterQuery_footer_contacts | null)[] | null;
}

export interface FooterQuery {
  footer: FooterQuery_footer | null;
}

export interface FooterQueryVariables {
  locale?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CategoryDetailQuery
// ====================================================

export interface CategoryDetailQuery_category_packages_images {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface CategoryDetailQuery_category_packages_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface CategoryDetailQuery_category_packages_values {
  __typename: "Values";
  id: string;
  price: CategoryDetailQuery_category_packages_values_price | null;
}

export interface CategoryDetailQuery_category_packages {
  __typename: "Package";
  id: string;
  slug: string;
  title: string;
  shortDescription: string | null;
  images: (CategoryDetailQuery_category_packages_images | null)[] | null;
  values: (CategoryDetailQuery_category_packages_values | null)[] | null;
}

export interface CategoryDetailQuery_category {
  __typename: "Category";
  id: string;
  title: string;
  packages: (CategoryDetailQuery_category_packages | null)[] | null;
}

export interface CategoryDetailQuery {
  category: CategoryDetailQuery_category | null;
}

export interface CategoryDetailQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PackageDetailQuery
// ====================================================

export interface PackageDetailQuery_package_images {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface PackageDetailQuery_package_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface PackageDetailQuery_package_values_image {
  __typename: "UploadFile";
  url: string;
  alternativeText: string | null;
}

export interface PackageDetailQuery_package_values_activities_images {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface PackageDetailQuery_package_values_activities {
  __typename: "Activity";
  id: string;
  slug: string;
  order: number | null;
  title: string;
  minAmountOfPeople: number | null;
  images: (PackageDetailQuery_package_values_activities_images | null)[] | null;
}

export interface PackageDetailQuery_package_values {
  __typename: "Values";
  id: string;
  title: string | null;
  price: PackageDetailQuery_package_values_price | null;
  items: string | null;
  image: PackageDetailQuery_package_values_image | null;
  activities: (PackageDetailQuery_package_values_activities | null)[] | null;
}

export interface PackageDetailQuery_package_reviews {
  __typename: "ComponentPackageReview";
  author: string | null;
  text: string | null;
}

export interface PackageDetailQuery_package {
  __typename: "Package";
  id: string;
  title: string;
  slug: string;
  longDescription: string | null;
  images: (PackageDetailQuery_package_images | null)[] | null;
  values: (PackageDetailQuery_package_values | null)[] | null;
  reviews: (PackageDetailQuery_package_reviews | null)[] | null;
}

export interface PackageDetailQuery {
  package: PackageDetailQuery_package | null;
}

export interface PackageDetailQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivityDetailQuery
// ====================================================

export interface ActivityDetailQuery_activity_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface ActivityDetailQuery_activity_values {
  __typename: "Values";
  id: string;
  price: ActivityDetailQuery_activity_values_price | null;
}

export interface ActivityDetailQuery_activity_images {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface ActivityDetailQuery_activity_coordinates {
  __typename: "ComponentGlobalCoordinates";
  lat: number | null;
  lng: number | null;
}

export interface ActivityDetailQuery_activity_reviews {
  __typename: "ComponentPackageReview";
  author: string | null;
  text: string | null;
}

export interface ActivityDetailQuery_activity {
  __typename: "Activity";
  id: string;
  title: string;
  slug: string;
  longDescription: string | null;
  values: (ActivityDetailQuery_activity_values | null)[] | null;
  images: (ActivityDetailQuery_activity_images | null)[] | null;
  coordinates: ActivityDetailQuery_activity_coordinates | null;
  reviews: (ActivityDetailQuery_activity_reviews | null)[] | null;
  priceType: ENUM_ACTIVITY_PRICETYPE;
  minAmountOfPeople: number | null;
}

export interface ActivityDetailQuery {
  activity: ActivityDetailQuery_activity | null;
}

export interface ActivityDetailQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivitiesQuery
// ====================================================

export interface ActivitiesQuery_activities_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface ActivitiesQuery_activities_values {
  __typename: "Values";
  id: string;
  price: ActivitiesQuery_activities_values_price | null;
}

export interface ActivitiesQuery_activities_images {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface ActivitiesQuery_activities {
  __typename: "Activity";
  id: string;
  slug: string;
  title: string;
  order: number | null;
  shortDescription: string | null;
  priceType: ENUM_ACTIVITY_PRICETYPE;
  values: (ActivitiesQuery_activities_values | null)[] | null;
  images: (ActivitiesQuery_activities_images | null)[] | null;
}

export interface ActivitiesQuery {
  activities: (ActivitiesQuery_activities | null)[] | null;
}

export interface ActivitiesQueryVariables {
  filters: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketPackagesQuery
// ====================================================

export interface BasketPackagesQuery_packages_values_image {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface BasketPackagesQuery_packages_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface BasketPackagesQuery_packages_values_activities {
  __typename: "Activity";
  id: string;
  title: string;
  order: number | null;
}

export interface BasketPackagesQuery_packages_values {
  __typename: "Values";
  id: string;
  image: BasketPackagesQuery_packages_values_image | null;
  price: BasketPackagesQuery_packages_values_price | null;
  title: string | null;
  activities: (BasketPackagesQuery_packages_values_activities | null)[] | null;
}

export interface BasketPackagesQuery_packages {
  __typename: "Package";
  id: string;
  slug: string;
  title: string;
  values: (BasketPackagesQuery_packages_values | null)[] | null;
}

export interface BasketPackagesQuery {
  packages: (BasketPackagesQuery_packages | null)[] | null;
}

export interface BasketPackagesQueryVariables {
  packageIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketActivitiesQuery
// ====================================================

export interface BasketActivitiesQuery_activities_values_price {
  __typename: "ComponentValuesPrice";
  amount: number | null;
  currency: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface BasketActivitiesQuery_activities_values {
  __typename: "Values";
  id: string;
  price: BasketActivitiesQuery_activities_values_price | null;
  title: string | null;
}

export interface BasketActivitiesQuery_activities {
  __typename: "Activity";
  id: string;
  slug: string;
  title: string;
  values: (BasketActivitiesQuery_activities_values | null)[] | null;
}

export interface BasketActivitiesQuery {
  activities: (BasketActivitiesQuery_activities | null)[] | null;
}

export interface BasketActivitiesQueryVariables {
  activityIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivitiesPageQuery
// ====================================================

export interface ActivitiesPageQuery_activitiesPage_seo {
  __typename: "ComponentGlobalSeo";
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface ActivitiesPageQuery_activitiesPage {
  __typename: "ActivitiesPage";
  seo: ActivitiesPageQuery_activitiesPage_seo | null;
  title: string | null;
  description: string | null;
}

export interface ActivitiesPageQuery_activityFilters_icon {
  __typename: "UploadFile";
  id: string;
  url: string;
  alternativeText: string | null;
}

export interface ActivitiesPageQuery_activityFilters {
  __typename: "ActivityFilters";
  id: string;
  title: string | null;
  type: ENUM_ACTIVITYFILTERS_TYPE | null;
  icon: ActivitiesPageQuery_activityFilters_icon | null;
}

export interface ActivitiesPageQuery {
  activitiesPage: ActivitiesPageQuery_activitiesPage | null;
  activityFilters: (ActivitiesPageQuery_activityFilters | null)[] | null;
}

export interface ActivitiesPageQueryVariables {
  locale: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PageBySlugQuery
// ====================================================

export interface PageBySlugQuery_pageBySlug_seo {
  __typename: "ComponentGlobalSeo";
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface PageBySlugQuery_pageBySlug {
  __typename: "Page";
  id: string;
  title: string | null;
  content: string | null;
  seo: PageBySlugQuery_pageBySlug_seo | null;
}

export interface PageBySlugQuery {
  pageBySlug: PageBySlugQuery_pageBySlug;
}

export interface PageBySlugQueryVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_ACTIVITYFILTERS_TYPE {
  active = "active",
  adrenaline = "adrenaline",
  chillout = "chillout",
  culture = "culture",
  driving = "driving",
  food = "food",
  nightLife = "nightLife",
  tours = "tours",
  welness = "welness",
}

export enum ENUM_ACTIVITY_PRICETYPE {
  perActivity = "perActivity",
  perPerson = "perPerson",
}

export enum ENUM_COMPONENTFOOTERCONTACTS_TYPE {
  email = "email",
  phone = "phone",
}

export enum ENUM_COMPONENTVALUESPRICE_CURRENCY {
  dollar = "dollar",
  euro = "euro",
}

export enum ENUM_ORDERS_LANGUAGE {
  de = "de",
  en = "en",
}

export enum ENUM_ORDERS_PAYMENTTYPE {
  applePay = "applePay",
  bankTransfer = "bankTransfer",
  card = "card",
  googlePay = "googlePay",
  paypal = "paypal",
}

export interface CreateCompleteOrderInput {
  paymentType: ENUM_ORDERS_PAYMENTTYPE;
  arrivalDate?: any | null;
  departureDate?: any | null;
  paymentId?: string | null;
  user: CustomerInput;
  language: ENUM_ORDERS_LANGUAGE;
  orderItems: OrderItemInput[];
}

export interface CustomerInput {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  country?: string | null;
  city?: string | null;
  zip?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface InputID {
  id: string;
}

export interface OrderItemInput {
  quantity?: number | null;
  value?: string | null;
  order?: string | null;
  published_at?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface editComponentValuesPriceInput {
  id?: string | null;
  amount?: number | null;
  currency?: ENUM_COMPONENTVALUESPRICE_CURRENCY | null;
}

export interface editOrderInput {
  specialRequirement?: string | null;
  publicId?: string | null;
  order_items?: (string | null)[] | null;
  customer?: string | null;
  totalPrice?: editComponentValuesPriceInput | null;
  deposit?: editComponentValuesPriceInput | null;
  paymentType?: ENUM_ORDERS_PAYMENTTYPE | null;
  paymentId?: string | null;
  language?: ENUM_ORDERS_LANGUAGE | null;
  arrivalDate?: any | null;
  departureDate?: any | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface updateOrderInput {
  where?: InputID | null;
  data?: editOrderInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
