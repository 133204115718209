import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { useRouter } from "next/dist/client/router";
import { useEffect, useState } from "react";
import LogoSVG from '../../assets/logo.svg';
import { headerQuery } from "../../graphql/queries";
import { HeaderQuery, HeaderQueryVariables } from "../../graphql/types";
import { breakpointsNum } from "../../libs/emotion-theme";
import CustomLink from "../CustomLink";
import Flex from "../Flex";
import LanguageSelect from "./LanguageSelect";
import Menu from "./Menu";

const Header = () => {
  const router = useRouter();

  const { data } = useQuery<HeaderQuery, HeaderQueryVariables>(headerQuery, {
    variables: {
      locale: router.locale
    }
  });

  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isHomepage = router.pathname === "/";

  useEffect(() => {
    const onScroll = (e: any) => {
      const { scrollTop: scrollTopPosition } = e.target.documentElement;
      const innerWidth = typeof window !== "undefined" && window.innerWidth;
      const isMobile = innerWidth < breakpointsNum[1];
      const yPositioToShowMenu = isHomepage ? (isMobile ? 800 : 400) : 200;
      if (scrollTopPosition > yPositioToShowMenu && !isSticky) {
        setIsSticky(true);
      } else if (scrollTopPosition < yPositioToShowMenu && isSticky) {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isSticky, isHomepage]);

  const isBlack = isSticky || !isHomepage || isMenuOpen;

  return (
    <HeaderContainer
      position={isSticky || isMenuOpen ? "sticky" : "relative"}
      isBlack={isBlack}
      isSticky={isSticky || isMenuOpen}
    >
      <HeaderContent
        justifyContent={{ _: "space-between" }}
        pr={{ _: 3, md: 4 }}
        pl="24px"
        py={2}
      >
        <Flex alignItems="center">
          {!isMenuOpen && <CustomLink href="/" ariaLabel="Prague Party Fun"><LogoSVG css={{
            color: isBlack ? "black" : "white",
            marginTop: isSticky ? 0 : 32,
            width: isSticky ? 56 : 64
          }}
          /></CustomLink>}
        </Flex>
        <Flex>
          <Menu
            items={data?.header?.menuItems}
            isBlack={isBlack}
            isOpen={isMenuOpen}
            onOpen={setIsMenuOpen}
          />
          {!!data?.header?.languageItems?.length &&
            <SelectInputsContainer>
              <LanguageSelect
                items={data?.header?.languageItems}
                isBlack={isBlack}
              />
            </SelectInputsContainer>}
        </Flex>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled(Flex) <{ isSticky: boolean; isBlack: boolean }>`
  background-color: ${({ isSticky, theme, isBlack }) =>
    isSticky || isBlack ? theme.colors.triatry : "transparent"};
  box-shadow: ${({ isSticky }) =>
    isSticky ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : "none"};
  z-index: 1;
  top: 0;
  width: 100%;
  right: 0;
  height: 56px;
`;

const HeaderContent = styled(Flex)`
  display: flex;
  height: 56px;
  align-items: center;
  max-width: 1200px;
  margin: auto auto;
  width: 100%;
`;

const SelectInputsContainer = styled(Flex)`
  align-items: center;
  margin-left: 16px;
`;