import styled from "@emotion/styled";
import React from "react";
import { default as SearchSVG } from "../assets/search.svg";
import Flex from "./Flex";
import Title from "./Title";
import SearchInput, { Option } from "./SearchInput";
import Box from "./Box";
import { breakpointsNum } from "../libs/emotion-theme";
import { useQuery } from "@apollo/client";
import { ActivitiesPageQuery, ActivitiesQuery, ActivitiesQueryVariables, BasketPackagesQuery } from "../graphql/types";
import { activitiesPageQuery, activitiesQuery, packagesQuery } from "../graphql/queries";
import { useTranslation } from "next-i18next";
import { useBasket } from "../providers/BasketProvider";

const BottomBar = () => {
  const [showBottomBar, setShowBottomBar] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const { data: pageData } = useQuery<ActivitiesPageQuery>(activitiesPageQuery);
  const allFilters = pageData?.activityFilters?.map((filter) => filter?.id);
  const { t } = useTranslation();
  const { totalPrice } = useBasket()

  const { data: activitiesData } = useQuery<ActivitiesQuery, ActivitiesQueryVariables>(
    activitiesQuery,
    {
      variables: {
        filters: (allFilters as string[]),
      },
      skip: allFilters ? allFilters.length < 1 : true,
    }
  );

  // TODO: need to generate correct types
  const { data: packagesData } = useQuery<any>(packagesQuery);

  let data = activitiesData?.activities?.map(a => ({ label: a?.title!, value: a?.slug!, imageUrl: a?.images?.[0]?.url!, id: a?.id!, type: "activity" as "activity" }))
  data = [...(data || []), ...(packagesData?.packages || [])?.map((p: any) => ({ value: p?.slug!, label: p?.title!, imageUrl: p?.images?.[0]?.url!, id: p?.id!, type: "package" as "package" }))]

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      const isMobile = innerWidth < breakpointsNum[1];

      if (showBottomBar) {
        return;
      }

      if (scrollTop > 50) {
        setShowBottomBar(true);
      } else {
        setShowBottomBar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showBottomBar]);


  return (
    <Container className={showBottomBar ? "show" : ""} pb={{ _: 3, md: 0 }} css={{ backgroundColor: "black" }} isBasketOpen={totalPrice > 0}>
      <Flex alignItems="center" marginX="auto" justifyContent="center" flexDirection={{ _: "column", md: "row" }} maxWidth={{ _: "100%", md: 600, lg: 800 }}>
        <Title level={3} css={{ textAlign: "center", color: "white", whiteSpace: "nowrap" }}>
          {t("searchBarTitle")}
        </Title>
        <Flex justifyContent="center" width={{ _: "80%", md: "100%" }} ml={{ _: 0, md: 5 }}>
          <SearchInput name="search" value={searchTerm} onInputChange={setSearchTerm} width="100%" options={data} />
          <Button
            aria-label="searchButton"
          >
            <SearchSVG />
          </Button>
        </Flex>
      </Flex>
    </Container >
  );
};

export default BottomBar;

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.text};
  width: 48px;
  height: 38px;
  margin-left: -1px;
  z-index: 1;
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-shrink: 0;
  justify-content: center;
  border: 3px solid white;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    width: 24px;
    height: 24px;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.border};
    cursor: default;
  }
`;

const Container = styled(Box) <{ isBasketOpen?: boolean }>`
position: fixed;
left: 0;
z-index: 2;
right: 0;
color: white;
justify-content: center;
align-items: center;
background-color: black;
transition: transform 0.5s ease;
transform: translateY(100%);
bottom: ${({ isBasketOpen }) => isBasketOpen ? '60px' : 0};

&.show {
  transform: translateY(0);
}
`;