import React from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import { useRouter } from "next/router";

interface Props {
  href?: string | object;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  shallow?: boolean;
  ariaLabel?: string | null;
}

const CustomLink = ({
  children,
  href,
  disabled,
  className,
  shallow,
  ariaLabel
}: Props) => {
  const { locale } = useRouter();
  return (
    <Link href={href || ""} shallow={shallow} passHref locale={locale}>
      <CustomLinkStyle isDisabled={disabled} className={className} aria-label={ariaLabel || undefined}>
        {children}
      </CustomLinkStyle>
    </Link>
  );
};

export default CustomLink;

const CustomLinkStyle = styled.a<{ isDisabled?: boolean }>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  position: relative;
  cursor: pointer;
`;
