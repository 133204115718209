import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

type Level = 1 | 2 | 3;

interface Props {
  level?: Level;
  children: React.ReactNode;
  className?: string
}

const Title = ({ level = 2, children, className }: Props) => {
  switch (level) {
    case 1:
      return <TitleH1 className={className} styleLevel={level}>{children}</TitleH1>;
    case 2:
      return <TitleH2 className={className} styleLevel={level}>{children}</TitleH2>;
    case 3:
      return <TitleH3 className={className} styleLevel={level}>{children}</TitleH3>;

    default:
      return null;
  }
};

export default Title;

const titleStyle = css`
  font-weight: 700;
  font-family: 'Kodchasan', sans-serif;
`;

const TitleH1 = styled.h1<{ styleLevel: Level }>`
  font-size: 58px;
  font-family: 'Kodchasan', sans-serif;
  ${titleStyle}
`;

const TitleH2 = styled.h2<{ styleLevel: Level }>`
  font-size: 32px;
  font-family: 'Kodchasan', sans-serif;
  ${titleStyle}
`;

const TitleH3 = styled.h3<{ styleLevel: Level }>`
  font-size: 24px;
  font-family: 'Kodchasan', sans-serif;
  ${titleStyle}
`;
