import styled from "@emotion/styled";
import React from "react";
import { default as BasketSVG } from "../../assets/shopping-cart.svg";
import { default as CloseSVG } from "../../assets/close-button.svg";
import { default as MinusSVG } from "../../assets/line.svg";
import { default as OpenSVG } from "../../assets/arrow-down.svg";
import { useRouter } from "next/dist/client/router";
import { BasketFooterWrapper } from "./CommonComponents";
import CustomLink from "../CustomLink";
import Flex from "../Flex";
import { Locale, localePath } from "../../routes";
import { useBasket } from "../../providers/BasketProvider";
import { makeSlugUrl } from "../../helpers/url";
import Box from "../Box";

const BasketFooter = () => {
  const { products, removeProduct, changeQuantity } = useBasket();
  const { locale } = useRouter();

  if (!products?.length) {
    return null;
  }

  const renderMobileFooter = () => {
    const text = products.map(p => `${p.quantity}x ${p.title}`).join(" ");
    return (
      <>
        <Box
          display={{ _: "blocck", md: "none" }}
          as="span"
          ml={3}
          css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}
        >
          {text}
        </Box>
        <CustomLink
          href={localePath[locale as Locale].checkout.index}
          ariaLabel="checkout"
        >
          <OpenIcon />
        </CustomLink>
      </>
    )
  }

  const renderDesktopFooter = () => (
    <Flex alignItems="center">
      <CustomLink
        href={localePath[locale as Locale].checkout.index}
        ariaLabel="checkout"
      >
        <BasketIcon />
      </CustomLink>
      <Flex flexWrap="wrap" display={{ _: "none", md: "flex" }}>
        {products.map((product, index) => (
          <Flex
            key={`basketProduct_${product.title}_${index}`}
            alignItems="center"
            flexWrap="wrap"
            ml={3}
          >
            {product.quantity}x
            {product.value?.image && (
              <ValueIcon src={product.value.image?.url} />
            )}
            <CustomLink
              ariaLabel="item-detail"
              href={
                product.activities
                  ? `/${localePath[locale as Locale].packages}/${makeSlugUrl({
                    id: product.productId!,
                    slug: product.productSlug!,
                  })}`
                  : `/${localePath[locale as Locale].activities
                  }/${makeSlugUrl({
                    id: product.productId!,
                    slug: product.productSlug!,
                  })}`
              }
              css={{ textTransform: "uppercase", marginLeft: 8 }}
            >
              {product.title}{" "}
            </CustomLink>
            <Button
              aria-label="plusButton"
              onClick={() => changeQuantity(product, product.quantity + 1)}
            >
              <CloseSVG style={{ transform: "rotate(45deg)" }} />
            </Button>
            <Button
              aria-label="minusButton"
              disabled={product.minAmountOfPeople >= product.quantity}
              onClick={() => changeQuantity(product, product.quantity - 1)}
            >
              <MinusSVG style={{ transform: "rotate(45deg)" }} />
            </Button>
            <Button
              aria-label="removeButton"
              onClick={() =>
                removeProduct(
                  product.productId as string,
                  product.value?.id as string
                )
              }
            >
              <CloseSVG />
            </Button>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )

  return (
    <BasketFooterWrapper>
      {renderDesktopFooter()}
      {renderMobileFooter()}
    </BasketFooterWrapper>
  );
};

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin-left: 8px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    width: 8px;
    height: 8px;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.border};
    cursor: default;
  }
`;

const ValueIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 0 0 16px;
`;

const BasketIcon = styled(BasketSVG)`
  height: 24px;
  width: 24px;
`;

const OpenIcon = styled(OpenSVG)`
  height: 22px;
  width: 22px;
  transform: rotate(-90deg);
  margin-left: 8px;
`;
export default BasketFooter;
