import styled from "@emotion/styled";
import Box from "../Box";
import Flex from "../Flex";

export const BasketFooterWrapper = ({
  children,
  black,
}: {
  children: React.ReactNode;
  black?: boolean;
}) => {
  return (
    <BasketFooterContainer black={black}>
      <Content px={{ _: 3, md: 4 }}>{children}</Content>
    </BasketFooterContainer>
  );
};

export const BasketFooterContainer = styled(Box) <{ black?: boolean }>`
  position: fixed;
  bottom: 0;
  padding: 16px 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${({ theme, black }) =>
    black ? theme.colors.text : theme.colors.triatry};
  box-shadow: rgba(99, 99, 99, 0.2) 0px -2px 8px 0px;
`;

const Content = styled(Flex)`
  max-width: 1200px;
  margin: auto auto;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
