export const getIdFromUrl = (slug?: string | string[]) => {
  let correctSlug = (Array.isArray(slug) ? slug[0] : slug) || "";
  const splitedSlug = correctSlug.split("-");
  return splitedSlug[splitedSlug?.length - 1];
};

export const getStringFromQuery = (slug?: string | string[]) => {
  return (Array.isArray(slug) ? slug[0] : slug) || "";
};

export const makeSlugUrl = (item?: { id: string; slug: string } | null) => {
  return `${item?.slug}-${item?.id}`;
};
