import styled from "@emotion/styled";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useRef } from "react";
import BasketFooter from "../basket/BasketFooter";
import Box from "../Box";
import Footer from "../Footer";
import Header from "../header/Header";
import { NextSeo } from "next-seo";
import BottomBar from "../BottomBar";
import { keyframes } from "@emotion/react";
import { mq, mx } from "../../libs/emotion-theme";
import { useBasket } from "../../providers/BasketProvider";

interface Props {
  children?: React.ReactNode;
  title?: string | null;
  metaDescription?: string | null;
  isDetail?: boolean;
}

const ShopLayout = ({ children, title, metaDescription, isDetail }: Props) => {
  const { pathname } = useRouter();
  const isCheckout = pathname.split("/")[1] === "checkout";
  const { totalPrice } = useBasket()

  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;

    const startShake = () => {
      (button as any).classList!.add('shake');
      setTimeout(() => {
        (button as any).classList.remove('shake');
      }, 2000); // Stop shaking after 2 seconds
    };

    const intervalId = setInterval(startShake, 50000); // Shake every 10 seconds

    // Start the first shake after 5 seconds
    setTimeout(startShake, 5000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <NextSeo
        title={title ? `Prague Party Fun: ${title}` : "Prague Party Fun"}
        description={metaDescription || undefined}
        defaultTitle="Prague Party Fun"
      />
      <Header />
      <Box flex={1}>
        <ShopLayoutContainer>
          <Box>{children}</Box>
        </ShopLayoutContainer>
      </Box>
      <WhatsAppButtonMobile href="https://wa.me/+420603863693" isBasket={totalPrice > 0 && !isDetail} isCheckout={isCheckout}><img ref={buttonRef} src="/waMobile.png" /></WhatsAppButtonMobile>
      <WhatsAppButton href="https://wa.me/+420603863693" isBasket={totalPrice > 0 && !isDetail} isCheckout={isCheckout}><img ref={buttonRef} src="/WhatsAppButtonGreenSmall.png" /></WhatsAppButton>
      {!isCheckout && <Footer />}
      {!isCheckout && !isDetail && <BottomBar />}
      {!isCheckout && <BasketFooter />}
    </>
  );
};

export default ShopLayout;

const ShopLayoutContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto auto;
`;


const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const WhatsAppButtonMobile = styled.a<{ isBasket: boolean, isCheckout: boolean }>`
    position: fixed;
    bottom: ${({ isBasket, isCheckout }) => isCheckout ? '80px' : isBasket ? '200px' : '140px'};
    left: 20px;
    cursor: pointer;

    ${mq[1]} {
      display: none
    }

    z-index: 20;

    img {
      width: 54px;
      &.shake {
        animation: ${shake} 0.5s ease-in-out 4;  // 0.5s duration, 4 iterations (2 seconds total)
      }

      :hover {
        animation: none;
      }
    }
`;


const WhatsAppButton = styled.a<{ isBasket: boolean, isCheckout: boolean }>`
    position: fixed;
    bottom: ${({ isBasket, isCheckout }) => isCheckout ? '80px' : isBasket ? '150px' : '100px'};
    left: 10px;
    cursor: pointer;
    z-index: 20;

    ${mx[1]} {
      display: none
    }

    img {
    width: 200px;
      &.shake {
    animation: ${shake} 0.5s ease-in-out 4;  // 0.5s duration, 4 iterations (2 seconds total)
  }
  border: 2px solid #1ccc5e;
  border-radius: 12px;

      :hover {
    animation: none;
  }
}
`;

