import { useQuery } from "@apollo/client";
import { css, Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { footerQuery } from "../graphql/queries";
import { FooterQuery } from "../graphql/types";
import { mq } from "../libs/emotion-theme";
import Box from "./Box";
import CustomLink from "./CustomLink";
import Flex from "./Flex";
import Image from "next/image";
import { getContactIcon, getContactUrl } from "../pages/contact";
import LogoSVG from "../assets/logo.svg";

import DiscoverSVG from "../assets/Discover.svg";
import VisaSVG from "../assets/Visa.svg";
import MastercardSVG from "../assets/Mastercard.svg";
import ApplePaySVG from "../assets/ApplePay.svg";
import GooglePay from "../assets/GooglePay.svg";
import PayPal from "../assets/PayPal.svg";
import Revolut from "../assets/Revolut.svg";

const Footer = () => {
  const { data } = useQuery<FooterQuery>(footerQuery);
  const { colors } = useTheme();
  return (
    <FooterContainer pb={4}>
      <FooterContent px={{ _: 4, md: 3 }}>
        <Flex
          justifyContent="space-between"
          flex={1}
          flexDirection={{ _: "column", md: "row" }}
          alignItems="flex-start"
        >
          <Flex flexWrap="wrap">
            {data?.footer?.links?.map((link) => (
              <CustomLink
                href={link?.url}
                css={linkStyle}
                key={link?.url}
                ariaLabel={link?.title || undefined}
              >
                {link?.title}
              </CustomLink>
            ))}
            <Flex width="100%" mt={{ _: -3, md: 3 }}>
              <CustomLink href="/" ariaLabel="Prague Party Fun">
                <LogoSVG color="white" css={{ width: 64, marginRight: 32 }} />
              </CustomLink>
            </Flex>
          </Flex>
          <Flex flexDirection="column" marginTop={{ _: 0, md: "-4px" }}>
            {data?.footer?.contacts?.map((contact) => (
              <ContactLink
                href={getContactUrl(contact?.link, contact?.type)}
                ariaLabel="contact"
                key={contact?.link}
              >
                <Flex mr={3}>
                  {getContactIcon(contact?.type, colors.primary)}
                </Flex>
                {contact?.title || contact?.link}
              </ContactLink>
            ))}
            <Flex flexDirection="column" justifyContent="center">
              <Flex>
                {data?.footer?.socialNetworks?.map((soc) => (
                  <CustomLink
                    href={soc?.url}
                    css={commonLinkStyle}
                    key={soc?.url}
                    ariaLabel="social-network"
                  >
                    <SocialNetworkImage
                      src={soc?.image?.url || ""}
                      width={24}
                      height={24}
                      alt={soc?.image?.alternativeText || ""}
                    />
                  </CustomLink>
                ))}
              </Flex>
              <Flex flexWrap="wrap" mx={-2}>
                <VisaSVG width={64} height={64} />
                <MastercardSVG width={64} height={64} />
                <DiscoverSVG width={64} height={64} />
                <ApplePaySVG width={64} height={64} />
                <GooglePay width={64} height={64} />
                <PayPal width={64} height={64} />
                <Flex
                  width={64}
                  height={64}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Revolut width={56} height={64} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContent = styled(Box)`
  max-width: 1200px;
  margin: auto auto;
  padding-top: 64px;
  padding-bottom: 112px;
`;

const FooterContainer = styled(Box)`
  background-color: black;
`;

const commonLinkStyle = (theme: Theme) => css`
  color: ${theme.colors.primary};
  margin-bottom: 16px;
  margin-right: 16px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const linkStyle = (theme: Theme) => css`
  ${commonLinkStyle(theme)};
  margin-right: 32px;
  ${mq[1]} {
    margin-right: 64px;
  }
  img {
    margin-right: 16px;
  }
`;

const ContactLink = styled(CustomLink)`
  ${({ theme }) => linkStyle(theme)};
  text-transform: lowercase;
`;

const SocialNetworkImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-right: 16px;
`;
