import styled from "@emotion/styled";
import React from "react";
import Select, {
    components,
    OptionProps,
    SingleValueProps,
    Styles,
} from "react-select";
import Flex from "./Flex";
import CustomLink from "./CustomLink";
import { Locale, localePath } from "../routes";
import { useRouter } from "next/router";
import { makeSlugUrl } from "../helpers/url";
import { useTranslation } from "next-i18next";

export interface Option {
    value?: string | number | null;
    label?: string | null;
    id: string;
    type: "activity" | "package";
    imageUrl: string;
}

interface Props {
    options?: Option[] | null;
    width?: string;
    maxWidth?: string;
    name: string;
    infoText?: string;
    label?: string;
    className?: string;
    value: string;
    onInputChange: (value: string) => void;
}

const iconWidth = "48px";

const Option = ({ children, ...props }: OptionProps<any, boolean>) => {
    const { locale } = useRouter();
    return (
        <components.Option {...props}>
            <CustomLink
                href={props.data.type === "activity" ?
                    `${localePath[locale as Locale].activities}/${makeSlugUrl({ id: props.data.id, slug: props.data.value })}` :
                    `${localePath[locale as Locale].packages}/${makeSlugUrl({ id: props.data.id, slug: props.data.value })}`}
            >
                <OptionContainer>
                    {props.data.imageUrl && <img
                        src={props.data.imageUrl}
                        width={iconWidth}
                        height={iconWidth}
                        css={{ marginRight: 16 }}
                        alt={props.data.label}
                    />}
                    {props.data.label}
                </OptionContainer>
            </CustomLink>
        </components.Option >
    )
}


const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
        {props.data.imageUrl && <img
            src={props.data.imageUrl}
            width={iconWidth}
            css={{ marginRight: 16 }}
            alt={props.data.label}
        />}
        {props.data.label}
    </components.SingleValue>
);

const SearchInput = ({ options, width, name, onInputChange, value, maxWidth }: Props) => {
    const { t } = useTranslation();
    const { push, locale } = useRouter();
    const customStyles: Partial<Styles<any, false>> = {
        container: (provided) => ({
            ...provided,
            width: width || "100%",
            maxWidth: maxWidth || "auto",
            flexShrink: 0,
            cursor: 'auto'
        }),
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            borderRadius: 0,
            cursor: 'auto !important'
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: 0,
        }),
        indicatorsContainer: () => ({
            display: 'none'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'lightgray' : 'white',
            '&:hover': {
                backgroundColor: 'lightgray', // Change background color on hover
                color: 'black', // Change text color on hover
            }
        })
    };

    const handleChange = (opt: Option) => {
        push(opt.type === "activity" ?
            `${localePath[locale as Locale].activities}/${makeSlugUrl({ id: opt.id, slug: opt.value!.toString() })}` :
            `${localePath[locale as Locale].packages}/${makeSlugUrl({ id: opt.id, slug: opt.value!.toString() })}`
        );
    };

    return (
        <Select
            styles={customStyles}
            aria-label={name}
            options={options || []}
            isSearchable
            onInputChange={onInputChange}
            onChange={handleChange}
            menuPlacement="top"
            value={value}
            menuIsOpen={value.length > 1}
            components={{ Option, SingleValue }}
            placeholder={t("searchBarPlaceholder")}
            instanceId="countrySelect"
        />
    );
};

export default SearchInput;

const OptionContainer = styled(Flex)`
    align-items: center;
    color: black;
`;