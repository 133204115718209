import styled from "@emotion/styled";
import React from "react";
import { css, Theme, useTheme } from "@emotion/react";
import CustomLink from "../CustomLink";
import { HeaderQuery_header_menuItems } from "../../graphql/types";
import Flex from "../Flex";
import { default as MenuSVG } from "../../assets/menu.svg";
import { default as CloseMenuSVG } from "../../assets/close-button.svg";
import { mq } from "../../libs/emotion-theme";

interface Props {
  items?: (HeaderQuery_header_menuItems | null)[] | null;
  isBlack: boolean;
  onOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}

const Menu = ({ items, isBlack, isOpen, onOpen }: Props) => {
  const theme = useTheme();
  return (
    <Flex>
      <MobileMenuContainer>
        <HamburgerButton onClick={() => onOpen((prevState) => !prevState)}>
          {isOpen ? (
            <CloseMenuSVG
              style={{
                width: 24,
                height: 24,
                color: isBlack ? theme.colors.text : theme.colors.secondary,
              }}
            />
          ) : (
            <MenuSVG
              style={{
                width: 50,
                height: 30,
                color: isBlack ? theme.colors.text : theme.colors.secondary,
              }}
            />
          )}
        </HamburgerButton>
        {isOpen && (
          <MobileMenuList>
            {items?.map((item) => (
              <MobileMenuListItem key={`mobileMenuItem${item?.id}`}>
                <CustomLink
                  href={item?.slug || ""}
                  ariaLabel={item?.title}
                  css={menuLinkStyle}
                >
                  {item?.title}
                </CustomLink>
              </MobileMenuListItem>
            ))}
          </MobileMenuList>
        )}
      </MobileMenuContainer>
      <MenuList>
        {items?.map((item) => (
          <MenuListItem key={item?.slug} isBlack={isBlack}>
            <CustomLink
              ariaLabel={item?.title}
              href={item?.slug || ""}
              css={menuLinkStyle}
            >
              {item?.title}
            </CustomLink>
          </MenuListItem>
        ))}
      </MenuList>
    </Flex>
  );
};

export default Menu;

const MenuList = styled.ul`
  display: none;
  ${mq[1]} {
    display: flex;
  }
  list-style: none;
  margin: 0;
`;

const MobileMenuList = styled.ul`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 4px;
`;

const MenuListItem = styled.li<{ isBlack: boolean }>`
  align-items: center;
  color: ${({ isBlack, theme }) =>
    isBlack ? theme.colors.text : theme.colors.primary};
  display: flex;
`;

const MobileMenuListItem = styled.li`
  color: ${({ theme }) => theme.colors.text};
  padding: 16px 32px;
  background: ${({ theme }) => theme.colors.triatry};
  align-items: center;
  display: flex;

  :hover {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const menuLinkStyle = (theme: Theme) => css`
  margin-left: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.small};
  :hover {
    text-decoration: underline;
  }
`;

const MobileMenuContainer = styled(Flex)`
  ${mq[1]} {
    display: none;
  }
`;

const HamburgerButton = styled.button`
  background: none;
  border: none;
  width: 50px;
  padding: 0;
  color: white;
  cursor: pointer;
`;
