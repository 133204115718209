import { useQuery } from "@apollo/client";
import { css, Theme, useTheme } from "@emotion/react";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";
import { default as CallSVG } from "../assets/call.svg";
import { default as EmailSVG } from "../assets/email.svg";
import CustomLink from "../components/CustomLink";
import Flex from "../components/Flex";
import ShopLayout from "../components/layouts/ShopLayout";
import Title from "../components/Title";
import { footerQuery, headerQuery } from "../graphql/queries";
import {
  ENUM_COMPONENTFOOTERCONTACTS_TYPE,
  FooterQuery
} from "../graphql/types";
import { initializeApollo } from "../libs/apollo-client";

export const getContactUrl = (
  url?: string | null,
  type?: "email" | "phone" | null
) => {
  if (type === "email") {
    return `mailto:${url}`;
  }
  return `tel:${url}`;
};

export const getContactIcon = (
  type?: ENUM_COMPONENTFOOTERCONTACTS_TYPE,
  color?: string,
  size?: number
) => {
  if (type === ENUM_COMPONENTFOOTERCONTACTS_TYPE.phone) {
    return (
      <CallSVG width={size || 24} height={size || 24} fill={color || "black"} />
    );
  }
  return (
    <EmailSVG width={size || 24} height={size || 24} fill={color || "black"} />
  );
};

const ContactPage = () => {
  const { t } = useTranslation("contact");
  const { data } = useQuery<FooterQuery>(footerQuery);
  const { colors } = useTheme();
  return (
    <ShopLayout>
      <Flex
        px={3}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Title level={1}>{t("title")}</Title>
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mb={5}
        >
          {data?.footer?.contacts?.map((contact) => (
            <CustomLink
              href={getContactUrl(contact?.link, contact?.type)}
              css={commonLinkStyle}
              key={contact?.link}
            >
              <Flex mr={3}>{getContactIcon(contact?.type, colors.text)}</Flex>
              {contact?.title || contact?.link}
            </CustomLink>
          ))}
          <b css={{ textTransform: "uppercase" }}>{t("billingInfo")}</b>
          <Flex justifyContent="center" css={{ textAlign: "center" }} mt={3}>
            Prague Party Fun s.r.o. <br />
            Novovysočanská 2509/3d, Libeň, 19000 Praha 9 <br />
            IČO: 08471380 <br />
            Bank account: 333222277/5500 <br />
            IBAN: CZ7055000000000333222277 <br />
            SWIFT: RZBCCZPP <br />
          </Flex>
        </Flex>
      </Flex>
    </ShopLayout>
  );
};

export default ContactPage;

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const apolloClient = initializeApollo(null, locale);
  await apolloClient.query({
    query: footerQuery,
  });
  await apolloClient.query({
    query: headerQuery
  });

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
      ...(await serverSideTranslations(locale as string, ["contact"])),
    },
  };
};

const commonLinkStyle = (theme: Theme) => css`
  color: ${theme.colors.text};
  display: flex;
  font-weight: 700;
  align-items: center;
  margin-bottom: 32px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;
