import { useTheme } from "@emotion/react";
import React from "react";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  Styles,
} from "react-select";
import { HeaderQuery_header_languageItems } from "../../graphql/types";
import { darken, transparentize } from "polished";
import { useRouter } from "next/dist/client/router";
import Image from "next/image";

interface Props {
  items?: (HeaderQuery_header_languageItems | null)[] | null;
  isBlack: boolean;
}

const Option = ({ children, ...props }: OptionProps<any, boolean>) => (
  <components.Option {...props}>
    <Image
      src={props.data.image}
      alt={props.data.label}
      width={24}
      height={24}
    />
  </components.Option>
);

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue {...props}>
    <Image
      src={props.data.image}
      alt={props.data.label}
      width={24}
      height={24}
    />
  </components.SingleValue>
);

const LanguageSelect = ({ items, isBlack }: Props) => {
  const { colors } = useTheme();
  const customStyles: Partial<Styles<any, false>> = {
    container: (provided) => ({
      ...provided,
      width: "80px",
      background: "none",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      background: "none",
      boxShadow: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isBlack ? colors.text : provided.color,
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      background: "none",
      borderRadius: 0,
      top: "50px",
      borderColor: isBlack ? colors.text : "transparent",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      color: isBlack ? colors.text : colors.primary,
      background:
        state.isSelected || state.isFocused
          ? isBlack
            ? colors.triatry
            : transparentize(0.8, colors.triatry)
          : isBlack
          ? darken(0.03, colors.triatry)
          : "transparent",
    }),
  };

  const options = items?.map((item) => ({
    value: item?.value,
    label: item?.title,
    image: item?.image?.url,
  }));

  const { push, locale } = useRouter();

  const handleChange = (selectedValue: { value: any }) => {
    push("/", "/", { locale: selectedValue.value as string });
  };

  const selectedValue = options?.find((o) => o.value === locale);

  return (
    <Select
      defaultValue={selectedValue || options?.[0]}
      options={options}
      isSearchable={false}
      instanceId="languageSelect"
      aria-label="language"
      styles={customStyles}
      components={{ Option, SingleValue }}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

export default LanguageSelect;
